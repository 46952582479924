import React, { useState, useEffect } from 'react'
import { Button, Container } from "semantic-ui-react";
import { Radio } from 'react-bootstrap';
import { toast } from "react-toastify";
import { isWaitingAnswers } from '../../utils/Api';
import { map } from "lodash";
// import axios from 'axios';
import Qs from "qs";
import firebase from "../../utils/Firebase";
import logo from '../../logo.svg';
import './U1Test.scss';

const db = firebase.firestore(firebase);


const answertres = "https://docs.google.com/spreadsheets/d/1kxN8Ujp78mD3mtqORX8QsvD3e07VmtWsSXRc8f3sZ_c/edit?resourcekey#gid=2050586978";

const skilltres = "Test";

function U1Test(props) {

    const { user, setReloadApp, idclass, classname, teachername } = props;
    //console.log(idclass);
    //console.log(user.uid); 
    const [formData, setformData] = useState("");
    const [waitingtres, setWaitingtres] = useState(false);
    const [userDetailstres, setUserDetailstres] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    //  const [teacheridtres, setTeacheridtres] = useState(null);
    //console.log(userDetailstres);
    //console.log(teachername);
    //console.log(idclass);
    //console.log(classname);


    ////////////////course details user
    useEffect(() => {
        db.collection('waiting')
            .doc(user.uid).get()
            .then(snapshot => setUserDetailstres(snapshot.data()))
        //   setMyclass(arrayofClasses);

    }, [])
    ////////////////

    ////////////set is waiting answers  
    useEffect(() => {
        isWaitingAnswers(user.uid).then(response => {
            setWaitingtres(response);
            // console.log(response);
        });
    }, [user])
    /////////formdata state  

    const onChange = e => {
        // console.log("Key:" +e.target.name);
        // console.log("Value:" +e.target.value);

        setformData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }
    /////////////send response record
    const respuesta = () => {

        db.collection("answers")
            .add({

                user: user.uid,
                useremail: user.email,
                username: user.displayName,
                userwork: "U1Test",
                usercourse: userDetailstres.course,
                userlevel: userDetailstres.level,
                userclassid: idclass,
                userclassname: classname,
                avatarUser: user.photoURL,
                createAt: new Date(),
                Teacher: teachername,
                answerlink: answertres,
                studentskill: skilltres,

            })
            .then(() => {
                toast.success("The responses are already sent.");
                // resetForm();
                setIsLoading(false);
                // setShowModal(false);
            })
            .catch(() => {
                toast.warning("Error recording the responses.");
                setIsLoading(false);
            });
    }
    ///////reset fields
    const resetFields = () => {
        document.getElementById("u1-course-form").reset();
    }
    /////////////  

    ///////////delete function
    const borrar = () => {
        //console.log(cartid);
        db.collection('waiting')
            .doc(user.uid)
            .delete()
            .then(() => {
                // console.log("unit exercises completed!");
                toast.success("You already completed the unit exercises.");
                setReloadApp(prevState => !prevState);
                //this.props.history.push("/")
                //window.location.reload();
            }).catch((error) => {
                toast.error("Error completing the unit exercises.");
                // console.error("Error removing document: ", error);
            });
    }

    ////////// on submit function
    const onSubmit = (e) => {

        //  console.log(formData);

        var str = Qs.stringify(formData)


        e.preventDefault();
        fetch('https://docs.google.com/forms/d/e/1FAIpQLScRcweADyUHx_mxPBkfKwdN7fc3ghx6zhrM9KtfJ3Ea25CvjA/formResponse', {
            method: 'post',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: str,
            redirect: 'follow'
        }).then(() => {
            respuesta();
            resetFields();
            borrar();
            setReloadApp(prevState => !prevState);
        })


    }
    ////////
    return (
        <>
            {waitingtres ? (

                <div className="App">
                    <header className="App-header">
                    <form  onChange={onChange} className="trabajo-U1" id="u1-course-form">
                    <fieldset>
                        <div>
                            <h2 className="U1">B12U3NTEST</h2>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className="parrafo-u1">
                            <p>Responde a las siguientes preguntas.</p>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="" className="leyenda-tres">Email</legend>
                        <div class="form-group">
                            <input id="emailAddress" type="email" name="emailAddress" class="form-control-estilo-tres" required/>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="716231463" className="leyenda-tres">1. ¿Tus padres se van de vacaciones mañana? ¡Que ______  unas vacaciones estupendas!</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1393700542" value="tengáis" required/>
                                    tengáis
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1393700542" value="tened" required/>
                                    tened
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1393700542" value="tengan" required/>
                                    tengan
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="214565366" className="leyenda-tres">2. Las expresiones de deseo "ojalá que....", "deseo que....", "quiero que...." van seguidas de verbos en __________.    </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.517185688" value="indicativo" required/>
                                    indicativo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.517185688" value="subjuntivo" required/>
                                    subjuntivo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.517185688" value="imperativo" required/>
                                    imperativo
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="2015282755" className="leyenda-tres">3. Si participas de una reunión de negociación, y necesitas expresar desacuerdo... ¿Qué frase usarías?</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1654019391" value="Déjeme decir algo...." required/>
                                    Déjeme decir algo....
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1654019391" value="No me parece que ....." required/>
                                    No me parece que .....
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1654019391" value="En resumen....." required/>
                                    En resumen.....
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1559532276" className="leyenda-tres">4. Si participas de una reunión de negociación, y quieres expresar tu opinión sobre un tema... ¿Qué frase usarías?</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.701474427" value="Desde mi punto de vista ......" required/>
                                    Desde mi punto de vista ......
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.701474427" value="No estoy de acuerdo..." required/>
                                    No estoy de acuerdo...
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.701474427" value="Yo quería decir una cosa...." required/>
                                    Yo quería decir una cosa....
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1290104294" className="leyenda-tres">5. Si participas de una reunión de negociación, y quieres pedir la palabra... ¿Qué frase usarías?</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1208772601" value="En conclusión...." required/>
                                    En conclusión....
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1208772601" value="¿Qué le parece?" required/>
                                    ¿Qué le parece?
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1208772601" value="Déjeme decir algo...." required/>
                                    Déjeme decir algo....
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="189076065" className="leyenda-tres">6. Si participas de una reunión de negociación, y quieres sintetizar las conclusiones... ¿Qué frase usarías?</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1418730125" value="Perdone que lo interrumpa..." required/>
                                    Perdone que lo interrumpa...
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1418730125" value="En resumen..." required/>
                                    En resumen...
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.1418730125" value="En cuanto a......." required/>
                                    En cuanto a.......
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1728824291" className="leyenda-tres">7. Tienes que ir bien preparado a la negociación __________. Los funcionarios de gobierno querrán beneficiar al sindicato. </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.2080926227" value="de equipo" required/>
                                    de equipo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.2080926227" value="de directorio" required/>
                                    de directorio
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.2080926227" value="colectiva" required/>
                                    colectiva
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="701822805" className="leyenda-tres">8. Al finalizar el Máster, el 80% de los graduados obtienen un contrato  _________ en empresas que tienen convenio con la Universidad.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.233362501" value="de prácticas" required/>
                                    de prácticas
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.233362501" value="indefinido" required/>
                                    indefinido
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.233362501" value="temporal" required/>
                                    temporal
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="754874466" className="leyenda-tres">9. En toda negociación, las partes tienen _________ divergentes e intentan llegar a un acuerdo. </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.445551925" value="compromisos" required/>
                                    compromisos
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.445551925" value="conflictos" required/>
                                    conflictos
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.445551925" value="intereses" required/>
                                    intereses
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1085452241" className="leyenda-tres">10. En la frase "espero conseguir un trabajo mejor", se expresa un deseo usando el verbo en. _______  </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.2091231300" value="subjuntivo" required/>
                                    subjuntivo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.2091231300" value="indicativo" required/>
                                    indicativo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-tres">
                                    <input type="radio" name="entry.2091231300" value="imperativo" required/>
                                    imperativo
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <input type="hidden" name="fvv" value="1"/>
                    <input type="hidden" name="fbzx" value="8461977738504272510"/>
                    <input type="hidden" name="pageHistory" value="0"/>
                    <div class="center">
                        <Button className="btn-primary-uno" onClick={onSubmit} isLoading={isLoading}>Send</Button>
                    </div>
                </form>





                    </header>
                </div>
            ) : (
                <div style={{ backgroundColor: '#101010', height: '260vh' }}>
                    <Container>
                        <p className="not-assigned-tres">
                            You already sent your answers!
                            <br></br>
                            or
                            <br></br>
                            You don't have a class assigned yet.
                        </p>
                    </Container>
                </div>
            )}
        </>
    )
}
export default U1Test;