import React, { useState, useEffect } from 'react'
import { Button, Container } from "semantic-ui-react";
import { Radio } from 'react-bootstrap';
import { isWaitingAnswers } from '../../utils/Api';
import { toast } from "react-toastify";
import { map } from "lodash";
import firebase from "../../utils/Firebase";

// import axios from 'axios';
import Qs from "qs";
import logo from '../../logo.svg';

import './L2work.scss';

const db = firebase.firestore(firebase);

const answerdos = "https://docs.google.com/spreadsheets/d/1_JUPmNGne16O3DKBPw-beqZ-CnwoX6pcOn291Qk9c0I/edit?resourcekey#gid=800485450";

const skilldos = "Grammar";

function L2work(props) {

    const { user, idclass, classname, teachername } = props;
    //console.log(user.uid);
    // console.log(idclass);
    const [formData, setformData] = useState("");
    const [waitingdos, setWaitingdos] = useState(false);
    const [userDetailsdos, setUserDetailsdos] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const [teacheridos, setTeacheridos] = useState(null);
    //console.log(userDetailsdos);
    //console.log(teachername);



    ////////////////course details user
    useEffect(() => {
        db.collection('waiting')
            .doc(user.uid).get()
            .then(snapshot => setUserDetailsdos(snapshot.data()))
        //   setMyclass(arrayofClasses);

    }, [])
    ////////////////

    ////////////set is waiting answers  
    useEffect(() => {
        isWaitingAnswers(user.uid).then(response => {
            setWaitingdos(response);
            // console.log(response);
        });
    }, [user])
    /////////  

    const onChange = e => {
        // console.log("Key:" +e.target.name);
        // console.log("Value:" +e.target.value);
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }
    /////////////send response record
    const respuesta = () => {

        db.collection("answers")
            .add({

                user: user.uid,
                useremail: user.email,
                username: user.displayName,
                userwork: "two",
                usercourse: userDetailsdos.course,
                userlevel: userDetailsdos.level,
                userclassid: idclass,
                userclassname: classname,
                avatarUser: user.photoURL,
                createAt: new Date(),
                Teacher: teachername,
                answerlink: answerdos,
                studentskill: skilldos,

            })
            .then(() => {
                toast.success("The responses are already sent.");
                // resetForm();
                setIsLoading(false);
                // setShowModal(false);
            })
            .catch(() => {
                toast.warning("Error recording the responses.");
                setIsLoading(false);
            });
    }
    ///////reset fields
    const resetFields = () => {
        document.getElementById("l2-course-form").reset();
    }
    ///////////// 

    ////submit function  
    const onSubmit = (e) => {

        var str = Qs.stringify(formData)


        e.preventDefault();
        fetch('https://docs.google.com/forms/d/e/1FAIpQLScRcweADyUHx_mxPBkfKwdN7fc3ghx6zhrM9KtfJ3Ea25CvjA/formResponse', {
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            mode: 'no-cors',
            body: str,
            redirect: 'follow'
        }).then(() => {
            respuesta();
            resetFields();
        })
        ///e.target.reset();

    };


    return (
        <>
            {waitingdos ? (

                <div className="App">
                    <header className="App-header">
                    <form  onChange={onChange} className="trabajo-l2" id="l2-course-form">
                    <fieldset>
                        <div>
                            <h2 className="L2">B12U3L2N</h2>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className="parrafo-dos">
                            <p>Responde a las siguientes preguntas.</p>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="" className="leyenda-dos">Email</legend>
                        <div class="form-group">
                            <input id="emailAddress" type="email" name="emailAddress" class="form-control-estilo-dos" required/>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="716231463" className="leyenda-dos">1. Espero que lleguemos a un acuerdo con los clientes y no ___________ una reclamación.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1393700542" value="hacen" required/>
                                    hacen
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1393700542" value="hacéis" required/>
                                    hacéis
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1393700542" value="hagan" required/>
                                    hagan
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="214565366" className="leyenda-dos">2. Ojalá que el nuevo gerente __________  capaz de conducir esta empresa. </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.517185688" value="es" required/>
                                    es
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.517185688" value="sea" required/>
                                    sea
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.517185688" value="eres" required/>
                                    eres
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="2015282755" className="leyenda-dos">3. En la frase anterior se expresa un deseo con el verbo en modo ____________.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1654019391" value="indicativo" required/>
                                    indicativo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1654019391" value="subjuntivo" required/>
                                    subjuntivo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1654019391" value="imperativo" required/>
                                    imperativo
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1559532276" className="leyenda-dos">4. Mi novio desea ____________ un trabajo mejor el año próximo.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.701474427" value="conseguir" required/>
                                    conseguir
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.701474427" value="consiga" required/>
                                    consiga
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.701474427" value="consigue" required/>
                                    consigue
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1290104294" className="leyenda-dos">5. En la frase anterior se expresa un deseo usando el verbo en modo ____________. </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1208772601" value="subjuntivo" required/>
                                    subjuntivo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1208772601" value="imperativo" required/>
                                    imperativo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1208772601" value="indicativo" required/>
                                    indicativo
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="189076065" className="leyenda-dos">6. Deseamos que les __________ bien en el viaje de negocios. </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1418730125" value="vayais" required/>
                                    vayais
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1418730125" value="vaya" required/>
                                    vaya
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.1418730125" value="van" required/>
                                    van
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1728824291" className="leyenda-dos">7. Vamos a comenzar la reunión. Espero que _______ una jornada provechosa y lleguemos a un acuerdo. </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.2080926227" value="tenemos" required/>
                                    tenemos
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.2080926227" value="tengo" required/>
                                    tengo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.2080926227" value="tengamos" required/>
                                    tengamos
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="701822805" className="leyenda-dos">8. Ojalá que mañana _________ buen tiempo. No quiero viajar con lluvia.  </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.233362501" value="haga" required/>
                                    haga
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.233362501" value="hace" required/>
                                    hace
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.233362501" value="haces" required/>
                                    haces
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="754874466" className="leyenda-dos">9. Juan quiere ___________ el puesto de gerente en esa empresa.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.445551925" value="consigue" required/>
                                    consigue
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.445551925" value="consiga" required/>
                                    consiga
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.445551925" value="conseguir" required/>
                                    conseguir
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1085452241" className="leyenda-dos">10. ¡Te felicito! Espero que __________ suerte en tu nuevo trabajo.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.2091231300" value="tener" required/>
                                    tener
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.2091231300" value="tengas" required/>
                                    tengas
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta-dos">
                                    <input type="radio" name="entry.2091231300" value="tienes" required/>
                                    tienes
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <input type="hidden" name="fvv" value="1"/>
                    <input type="hidden" name="fbzx" value="8461977738504272510"/>
                    <input type="hidden" name="pageHistory" value="0"/>
                    <div class="center">
                        <Button className="btn-primary-uno" onClick={onSubmit} isLoading={isLoading}>Send</Button>
                    </div>
                </form>






                    </header>
                </div>
            ) : (
                <div style={{ backgroundColor: '#101010', height: '260vh' }}>
                    <Container>
                        <p className="not-assigned-dos">
                            You already sent your answers!
                            <br></br>
                            or
                            <br></br>
                            You don't have a class assigned yet.
                        </p>
                    </Container>
                </div>
            )}
        </>
    )
}
export default L2work;