import React, { useState, useEffect } from 'react'
import { Button, Container } from "semantic-ui-react";
import { Radio } from 'react-bootstrap';
import { map } from "lodash";
// import axios from 'axios';
import { isWaitingAnswers } from '../../utils/Api';
import { toast } from "react-toastify";
import firebase from "../../utils/Firebase";
import Qs from "qs";

import logo from '../../logo.svg';
import './L1work.scss';

const db = firebase.firestore(firebase);

const answer = "https://docs.google.com/spreadsheets/d/1y3OkSY1zJ_sP9qKzYMOLryTA9Mc9nuRof-AljHZWzBY/edit?resourcekey#gid=1960186367";

const skill = "Vocabulary";

function L1work(props) {

    const { user, myclass, idclass, classname, teachername } = props;
    // console.log(user.uid);
    const [formData, setformData] = useState("");
    //const [myclass, setMyclass] = useState(null);
    const [userDetails, setUserDetails] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //const [teacherid, setTeacherid] = useState('');
    //console.log(myclass);
    //console.log(userDetails);
    //console.log(idclass);
    ////////input informations  
    //console.log(user);
    //console.log(classname);
    // console.log(teachername);


    ////////////////course details user
    useEffect(() => {
        db.collection('waiting')
            .doc(user.uid).get()
            .then(snapshot => setUserDetails(snapshot.data()))
        //   setMyclass(arrayofClasses);

    }, [])
    ////////////////

    ////////////set is waiting answers  
    useEffect(() => {
        isWaitingAnswers(user.uid).then(response => {
            setWaiting(response);
            // console.log(response);
        });
    }, [user])
    /////////  
    const onChange = e => {

        // console.log("Key:" +e.target.name);
        // console.log("Value:" +e.target.value);
        setformData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }
    /////////////send response record
    const respuesta = () => {

        db.collection("answers")
            .add({

                user: user.uid,
                useremail: user.email,
                username: user.displayName,
                userwork: "one",
                usercourse: userDetails.course,
                userlevel: userDetails.level,
                userclassid: idclass,
                userclassname: classname,
                avatarUser: user.photoURL,
                createAt: new Date(),
                Teacher: teachername,
                answerlink: answer,
                studentskill: skill,

            })
            .then(() => {
                toast.success("The responses are already sent.");
                // resetForm();
                setIsLoading(false);
                // setShowModal(false);
            })
            .catch(() => {
                toast.warning("Error recording the responses.");
                setIsLoading(false);
            });
    }
    ///////reset fields
    const resetFields = () => {
        document.getElementById("l1-course-form").reset();
    }


    //////////////////submit function  
    const onSubmit = (e) => {
        //console.log(formData);

        var str = Qs.stringify(formData)


        e.preventDefault();
        fetch('https://docs.google.com/forms/d/e/1FAIpQLSfqJ89cH5pVrLY1Zjzbnk9MYwXofe4m9xEEKjlj2hfDAP514Q/formResponse', {
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            mode: 'no-cors',
            body: str,
            redirect: 'follow'
        }).then(() => {
            respuesta();
            resetFields();
        })


    };
    return (
        <>
            {waiting ? (

                <div className="App">
                    <header className="App-header">
                    <form  onChange={onChange} className="trabajo-l1" id="l1-course-form">
                    <fieldset>
                        <div>
                            <h2 className="L1">B12U3L1N</h2>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className="parrafo-uno">
                            <p>Responde a las siguientes preguntas.</p>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="" className="leyenda">Email</legend>
                        <div class="form-group">
                            <input id="emailAddress" type="email" name="emailAddress" class="form-control" required/>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="716231463" className="leyenda">1. He finalizado el Master en Negocios y tengo un contrato en _________ en un banco.   </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1393700542" value="temporal" required/>
                                    temporal
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1393700542" value="indeterminado" required/>
                                    indeterminado
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1393700542" value="prácticas" required/>
                                    prácticas
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="214565366" className="leyenda">2. Los empresarios y los delegados sindicales se reúnen hoy para una _______________.  </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.517185688" value="contrato" required/>
                                    contrato
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.517185688" value="negociación colectiva" required/>
                                    negociación colectiva
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.517185688" value="acuerdo" required/>
                                    acuerdo
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="2015282755" className="leyenda">3. El ____________ colectivo de los trabajadores de la construcción establece que la jornada no puede superar las 8 horas.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1654019391" value="negocio" required/>
                                    negocio
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1654019391" value="convenio" required/>
                                    convenio
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1654019391" value="acuerdo" required/>
                                    acuerdo
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1559532276" className="leyenda">4. Mañana tendremos. una reunión de ________ para conocer el nuevo proyecto.  </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.701474427" value="equipo" required/>
                                    equipo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.701474427" value="negocios" required/>
                                    negocios
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.701474427" value="negociación" required/>
                                    negociación
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1290104294" className="leyenda">5. Todos los lunes el jefe hace un ___________ para coordinar las tareas de la semana.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1208772601" value="merienda de trabajo" required/>
                                    merienda de trabajo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1208772601" value="cena de trabajo" required/>
                                    cena de trabajo
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1208772601" value="desayuno de trabajo" required/>
                                    desayuno de trabajo
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="189076065" className="leyenda">6. No quiero trabajar más en esa empresa. Tiene pésimas condiciones _________.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1418730125" value="de contrato" required/>
                                    de contrato
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1418730125" value="laborales" required/>
                                    laborales
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.1418730125" value="de convenio" required/>
                                    de convenio
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1728824291" className="leyenda">7. Si la empresa interrumpe tu contrato debe ____________ .</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.2080926227" value="contratarte" required/>
                                    contratarte
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.2080926227" value="despedirte" required/>
                                    despedirte
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.2080926227" value="indeminizarte" required/>
                                    indeminizarte
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="701822805" className="leyenda">8. ¿Qué acciones se hacen habitualmente en una reunión de negocios?</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.233362501" value="dialogar, negociar, acordar" required/>
                                    dialogar, negociar, acordar
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.233362501" value="comer, compartir, celebrar" required/>
                                    comer, compartir, celebrar
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.233362501" value="socializar, distender" required/>
                                    socializar, distender
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="754874466" className="leyenda">9. Tengo un contrato __________ hasta fin de año.    </legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.445551925" value="indefinido" required/>
                                    indefinido
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.445551925" value="indeterminado" required/>
                                    indeterminado
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.445551925" value="temporal" required/>
                                    temporal
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend for="1085452241" className="leyenda">10. Espero que _________ todo bien en la reunión con los clientes.</legend>
                        <div class="form-group">
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.2091231300" value="vas" required/>
                                    vas
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.2091231300" value="vaya" required/>
                                    vaya
                                </label>
                            </div>
                            <div class="radio">
                                <label className="etiqueta">
                                    <input type="radio" name="entry.2091231300" value="va" required/>
                                    va
                                </label>
                            </div>
                        </div>
                    </fieldset>
                    <input type="hidden" name="fvv" value="1"/>
                    <input type="hidden" name="fbzx" value="8461977738504272510"/>
                    <input type="hidden" name="pageHistory" value="0"/>
                    <div class="center">
                        <Button className="btn-primary-uno" onClick={onSubmit} isLoading={isLoading}>Send</Button>
                    </div>
                </form>





                    </header>
                </div>

            ) : (
                <div style={{ backgroundColor: '#101010', height: '260vh' }}>
                    <Container>
                        <p className="not-assigned">
                            You already sent your answers!
                            <br></br>
                            or
                            <br></br>
                            You don't have a class assigned yet.
                        </p>
                    </Container>
                </div>
            )}
        </>
    )
}
export default L1work;