import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L4.scss'

export default class B11U1L4 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid style={{ backgroundColor: '#101010'}}>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2 className="lesson4title"><br></br>Lesson 4</h2>

        <Slider {...settings}>

        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F4167a2fc-0607-4945-b4d3-42f62a6aeedc?alt=media&token=d83b8a6d-16a3-4106-b123-b3e03024c81a"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2Ff9312d0d-f1c5-4b66-ac1d-3a94fed0534b?alt=media&token=2517c312-f6f4-4071-ba44-526fa45a5c96"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F0ba3f573-089e-4746-985e-5ca6bbcf33e1?alt=media&token=e5be66f5-9f19-4589-b9ef-cf6436ad1597"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F645bc527-9459-4612-85d7-1bcffedf342f?alt=media&token=842d6ce7-f143-4ce5-bbfb-e9ec8b703b79"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F1d7bcad3-9ada-48ec-86f2-cb0b6bc59464?alt=media&token=ca17ee36-1785-411f-af3e-ef4b2f824f8b"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2Ff3493065-7e5b-44cf-a23c-9777e8ee2b1a?alt=media&token=67e29b09-c24f-4c6a-bd99-73b558150d3a"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2Ff65b57e7-3d75-4635-b496-497683a5da73?alt=media&token=e1720e4e-db49-49a6-b2bd-06677058795d"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2Fcb32e595-37b9-4ef0-888a-a4c3de5c945e?alt=media&token=5ea2cec5-4cd3-4e25-946d-00ce5eab3749"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F86a1210a-59dc-41d3-ba03-819363d18fd4?alt=media&token=353a3c6b-243d-4d7d-baef-9401d4a2dbf8"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F496c6fb8-104c-43ec-ad6c-e2d02fc29278?alt=media&token=b5eeab6f-d968-4bd7-8791-c73ac89e2a12"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2Fd7c586d2-615d-445e-9b17-c7e7ce76e096?alt=media&token=f3a413f3-46d6-4023-a928-56a777a8e802"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F07873b42-bd68-4165-ba33-91079a61c045?alt=media&token=8d4bcdb2-3948-4f7f-8527-ec2d482e27f3"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F2513d141-576b-4bf3-ab87-a69ef335e3df?alt=media&token=2828c1e9-b3a1-4b24-821e-19ff337cebc4"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F53032a48-60ff-4aeb-bd23-eb3f5cb739db?alt=media&token=eae661b7-1687-46d8-85a6-c5e7247b302b"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F5c9a043c-2157-4cb8-876b-8f750a94460a?alt=media&token=6dbca02c-e22c-4cba-9a9b-a5eacfe21656"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F569d9b8a-6203-4c1a-baa9-fc2345b63c47?alt=media&token=7dd13ad9-8b40-41f4-b244-bbeac923f764"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F18db9a49-6a14-4b23-9d32-c66224c0129f?alt=media&token=0213a312-0bd3-4971-b247-ae5c8180afbd"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F388c78d7-4487-45cc-b5f6-c995dd39f13b?alt=media&token=b5fb10de-3497-4c49-b770-bcce016cc65d"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F3573a056-4725-429d-a2c2-f68d7f819b6e?alt=media&token=d725cb76-47d9-41d3-8326-8980ae9cbcb8"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F88a0a171-685f-478d-9749-ad1ba0ea8113?alt=media&token=02899472-30b6-4519-ad54-c80ba391802e"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F7b56fed7-f708-4d42-90d9-fe0c0f54a5fa?alt=media&token=9220aa38-34f9-4cff-a1b6-a46ab9fc4ab5"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F0602519e-5079-4b85-81b9-97bd1c022ebc?alt=media&token=7e81ac97-59a6-4c31-b2bd-709e3762443c"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F0eedf0e1-8943-4eae-8d27-21999db6ea1e?alt=media&token=4f2105e2-2acc-4c18-8a98-9ba209948b05"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2Fc79edaae-aa13-43f7-a803-4e5e8ceec850?alt=media&token=733ade19-00a6-4810-80f4-b725eaebac46"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F5ceef222-8de5-458d-b501-9c6ad26aef36?alt=media&token=4ede55cf-d206-46ae-ae63-7a39eeb213c0"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F9c15f509-450f-4000-a455-c8a01def3d43?alt=media&token=84515d51-54db-4dfc-9bb0-b997e50f7767"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2Fbbdf55ff-93ef-40b6-9be6-bfdd363f8a47?alt=media&token=46771927-c8b0-461d-8361-e8d62342b160"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F2e1bb82e-a1d9-43f2-bc8f-b628335b72a0?alt=media&token=85bbe59b-e801-4a8a-9e87-34419557a177"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F264c89f1-7233-435e-bdc9-16e1de230bc8?alt=media&token=82897342-b5a1-4bed-8754-4a4cf3b7f78c"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2Fbcfe31f4-aa60-43b6-87bb-3795ba7b9b50?alt=media&token=539791b3-b194-46ad-8a4d-9246e228b42a"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2F42fd0caf-f58e-4a71-ab09-df338f77def8?alt=media&token=e425579d-f4b1-4807-a7f1-e1a0b4881c83"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>





        </Slider>
        <div class="center"> 
        <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L4%2FB12U3NMAT.pdf?alt=media&token=f4a5baef-f977-4bc4-b79a-deebabd9fa73' download className="btn-download-cuatro">Download resources</Button>
        </div>    
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
