
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Fb222060d-e76b-4de3-a6cc-984e7d9c4578?alt=media&token=7794f010-1309-4566-b5d9-a70d62cdd74b"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F47af000e-6b73-4ecd-b1ef-31f5533784cf?alt=media&token=b249a9fb-3e50-4564-baf6-a14d130b4116"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F97049aa1-1ddb-42fa-b320-08e7dc130988?alt=media&token=4b673c3c-55b6-4f18-bfe3-4888b2edcb0f"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F7f3d8e93-2fb1-4085-89bf-1bd6721df0d9?alt=media&token=f6b7bb1c-46b5-41ee-a96f-0de89a15c997"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F91f912b8-3806-467c-8dd6-7da66f1f4ebb?alt=media&token=7a9bea67-5c81-4708-8015-5c46125373bf"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F9ea79c00-8e81-4688-a01f-b110afbc1520?alt=media&token=676cd7a3-3711-4bb9-ba79-330d1a87baa7"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Fcd094e5d-9548-48ea-bf1f-353b15a7e582?alt=media&token=a9b59bf5-b8ed-482c-a9c7-bcd4ff882f8a"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Fec8b3b50-5ccd-41cf-b43c-617fb48c5704?alt=media&token=c045b654-97a6-489b-959d-b8adb3df9511"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F624440d5-4dc6-480b-bcb0-1de9a700021a?alt=media&token=71df2c36-0180-4ecf-83ce-01f7facf6b69"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F7f05321e-1956-44a7-b9ed-bd52767378c1?alt=media&token=823d1eae-ec9b-467b-a985-2c69ffa1d378"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Fc6af6078-d063-4b3b-86ee-77a9ab05a5c9?alt=media&token=f4082b56-6337-4646-b038-bc59998a4b3f"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F8d348bac-48bd-47d3-939a-5142303c4ccb?alt=media&token=acb520b6-4bfc-4b2f-a86b-c2cb993bed3a"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Fcc190ba8-52b0-47dd-992a-f1247216fb9e?alt=media&token=0c585471-11c7-4f3d-ba7f-0dfbf1e8485b"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F5e19fbbb-f7b0-4686-9612-d2256ba9405a?alt=media&token=55c48504-00ea-4a50-840d-8d76da7f5910"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F47ca089f-a72a-4cdf-af89-4ec232bcdc9b?alt=media&token=e5ec56a5-a848-4ea0-96ea-b1abfce598f0"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F948ce1fc-1557-41be-867f-ddd99532b899?alt=media&token=8cf6ec97-0148-480d-b1e9-377138e39305"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F419fbe55-ae74-477a-81ab-826d851294e7?alt=media&token=085a03fc-7902-4a9e-b576-dae8c580499b"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F803c9d26-01cb-4815-b382-2981d7b5a854?alt=media&token=ba610c75-c541-4548-877a-237553c63b93"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F4a6d89cf-80fc-4d76-ae2f-7e5129e95e21?alt=media&token=cee0dd4e-d4d3-4220-908b-64ba31e53518"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Fef1175e9-f0d4-4be5-9623-aedfb261ae7c?alt=media&token=992e06d9-224c-4595-a2c9-974af38f6b7c"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F2c77a70c-4244-464d-be38-bdbcdef22e41?alt=media&token=f65d4de5-7c2d-41d1-b1a9-d7f882422824"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Fd996d2e7-09b4-4d96-8c4d-682817631a0c?alt=media&token=2cf7ccee-82e6-4547-8d03-93ffecfe7dcd"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F4f222bf1-83e6-4334-a332-c1d887627ec0?alt=media&token=cceac5d5-3f2e-4ce4-86f0-c10d391aebff"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F2c9e5c1a-da95-450c-bf73-1e2c056b0bf8?alt=media&token=b67bcc13-a650-4c35-96aa-a1e52d318acf"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F5ee5cebf-ffcd-4ff1-b64b-fda80db76b33?alt=media&token=cfa2a26d-526a-41bc-9ee1-18225456e2cd"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F1e159ec6-df9c-4a84-8241-a7f12c1793ee?alt=media&token=f713428f-e78a-45de-836b-d6cbdf3255e8"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F48f3182e-4da2-4e93-9b05-fd4e3fd9af7a?alt=media&token=56abead2-a771-409e-b5d1-c56ff9a7aed3"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F87a674cb-5f52-464d-afa2-57e83e3352d7?alt=media&token=0ba7a233-2b64-43e1-8e6d-0760b1103ec4"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F4371ac12-7e54-4fc0-bb0a-5483054f13cc?alt=media&token=4adddfee-66d9-4a90-a6ac-b9d5e37d4fe6"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Ffd38b266-da9f-4548-ab93-350148a818af?alt=media&token=35a5a917-7bbc-4c11-9788-18175ed2f869"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F29b84cd1-7f05-4f4a-98df-d606da66324f?alt=media&token=e9cad0a5-7612-4e08-bedc-fd9f976473de"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F8c5345f1-3f16-4b12-bc31-33d1cac40fd2?alt=media&token=d930a00d-b336-404e-8ba7-c9f7051aaa4a"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2Fb2af0074-19f1-4a2a-8d3f-69c5bb71bc46?alt=media&token=2dff39e8-faf9-42c2-a479-986d9d5a9bf4"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>
    <div>
      <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2F8255a480-d2d3-4c2c-8e96-de42858ba567?alt=media&token=c69bc03e-ec5a-4041-8314-716d3d6791c5"} alt="boohoo" className="img-responsive"/>
      <br/>
    </div>




        


        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L1%2FB12U3L1NREF.pdf?alt=media&token=bad07331-09fc-4ce3-a975-5564908964a4' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
