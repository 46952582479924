import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L5.scss'

export default class B11U1L5 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid style={{ backgroundColor: '#101010'}}>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2 className="lesson5title"><br></br>Lesson 5</h2>

        <Slider {...settings}>

        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F20f8ad8f-ee9e-4208-88f1-7207e0e01679?alt=media&token=4a273123-2c8e-4318-b5b4-5d0a8637c487"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F69989f29-e951-4197-9bc4-d411dd4aa995?alt=media&token=05225eb8-42fa-4e78-9f1a-e43b9fb0d02d"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F1c50f047-6d42-4168-b97a-e5639a58d34f?alt=media&token=d9df7cef-fe5d-4bfa-b2b3-8401460ff41a"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F4e83aced-8e4b-4e1b-bf81-5307f2e28912?alt=media&token=2b4156ee-34c9-4235-82ed-d5481640d98b"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F6471d106-cfe9-4b68-9d03-7dbb35955e10?alt=media&token=4aa44243-2496-44f2-b05a-d0fa0369db9f"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F289ceaad-ec3b-4e2c-b3d4-d18c24b87272?alt=media&token=f9646ae5-52c4-4dbc-b355-6b5709e919ed"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F1198f936-ca7c-483d-a353-7af4a12dcb95?alt=media&token=43d4c37c-1e30-481a-b0af-7e44b4f00208"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F6e545dd6-6860-4e2e-9ed9-b19197b341c0?alt=media&token=e6da7875-1064-4466-bbef-3cfd109b4e5f"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F4fe39bfe-0d29-4372-adbc-ef6962e4a524?alt=media&token=767a58fd-03b9-4819-8561-f6703cf39a21"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F5f08e10e-ebac-46c8-a3e1-4c82ad9b789e?alt=media&token=f49dcab6-b249-418f-aef6-e5a2ace92578"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F81b240fd-fbec-45ea-994d-27c8759ff1d4?alt=media&token=eff1c12b-06d5-4cfe-bcda-67c60702c2a9"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F4c62ddfb-17c8-4553-9bf7-592ea9d7082e?alt=media&token=1cfe7ed1-1325-44f2-9f97-8f72818390cf"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F411319df-959d-47dc-9c6e-29e1fb158b36?alt=media&token=ba5e6c7d-03aa-4406-be1c-61a48611ac3e"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fffe6ea1d-237a-42f2-8a8b-e895a527ba0c?alt=media&token=f1810f9b-63b9-45e5-a2ff-444b0f6dc56b"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fb74a9a82-9824-45b2-8e4f-c478f98cf523?alt=media&token=2c0b14c2-8a18-49ae-a3f3-b2de230b7187"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F8f32b744-1bf7-4912-b59a-203460a446db?alt=media&token=780a819b-e7c7-4c00-96ef-8d9a2778fa53"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F7e05253d-b170-4f65-bcdf-fb79091b5f1e?alt=media&token=1a198e38-6b15-4714-bbba-11332909759e"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fc1367247-5f15-4eb8-8926-d77cbaa9868f?alt=media&token=266233b5-df00-487e-858c-8a27afa3dbd0"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fd4f3d086-2108-4143-ad24-da54ad6a6f4f?alt=media&token=056fd1e2-af7c-437a-b553-f3732715ee4e"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fc76a4e95-438a-4bcb-9c65-76524507f9f0?alt=media&token=56610d1c-40d8-4136-8d02-ac729bce54b6"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F2dd17e13-162d-42bf-8bff-4eef11b2410c?alt=media&token=7380ebc7-2898-4317-b8a3-a6b3a020c448"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fcce25bcd-93ec-4e12-a500-cf3dc70073ca?alt=media&token=a392b1ae-b76c-40ec-8cac-3e2b55e63a6f"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fa3107708-219f-416d-99db-b8b10a59dabc?alt=media&token=eb1b2c82-430d-4172-a4a1-8f2336bf23cb"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F04d39e58-9e0d-4ac7-85d6-2b4df7818c9d?alt=media&token=b4355875-80e2-4048-a876-75bcbaa934d0"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F0069c155-d369-40b5-ab6a-a6529dd46fb5?alt=media&token=fd1fb9c9-14d2-4af0-a2ab-d0a6f1b6b072"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F8674e972-a6a0-4ea6-b4ac-d812131908d2?alt=media&token=2f8ddfff-995c-4281-b9eb-5ce7434b291c"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fa71daac7-68f1-4cfe-86c0-b102c582c1bd?alt=media&token=77832130-a42f-489e-89ff-34cf3483e0e0"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2Fc03ad11b-9f4e-474f-bec6-bc485f6d9e3c?alt=media&token=9e0b1bf7-27ae-4ac5-bc35-be4fce042d68"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F721f49cb-a22b-4cfa-b5d1-4beb123d5e05?alt=media&token=38475bba-5f72-4c7d-8e2e-949be52f67c2"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L5%2F9048459e-16ec-48fd-8606-99ca68aa55c1?alt=media&token=45b05861-aeba-44d2-9e42-869f57963507"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>




        

        </Slider>
   
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
