import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import '../../style.scss';
import './B11U1L2.scss';

export default class B11U1L2 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      // <div class="contenedor"> 
      // <div className="App">
      // <header className="App-header">
      <Grid style={{ backgroundColor: '#101010', height: '160vh'}}>
         <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
        <h2 className="lesson2title"><br></br>Lesson 2</h2>
        <Slider {...settings}>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F658dd9bd-c3e2-46c2-8c6d-653223996ac0?alt=media&token=0fb10404-ef3b-43f9-a167-c7dadce544bc"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Fb7a794e5-3820-442f-86e9-b289870f9d19?alt=media&token=0531bebe-a584-4a01-a7cf-08ddf47c2f26"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F787ad4ac-01b2-4527-a5c9-c864a5bf929c?alt=media&token=7f5ea265-720a-46d4-867c-125cb422b734"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F1d4cf863-e148-4802-8642-5042421cb3c3?alt=media&token=a107e1c2-dd62-4a6b-a615-43ad3f5e3aec"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F367c8d98-6e72-42c6-ab4c-8de877717fdc?alt=media&token=cfef537c-889b-4f35-a152-676f72b16745"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F2c296dbc-e371-4884-8aec-0507b36a04b1?alt=media&token=30dd0156-e858-467a-9979-2b8ab8d6262f"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F0386d625-fbaf-4267-9b9c-b1a887f51265?alt=media&token=7ea74f49-d71a-47a1-a2d3-076a7face2fc"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F07c99657-e22d-4267-8f0b-bedaa3b1769e?alt=media&token=0f6630d2-eea9-400f-86a7-73e42d5d6978"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Fedf57948-e34a-416b-80ff-0c1f5c954efc?alt=media&token=4675fdbf-8dca-41c6-a942-9e7b49ab08a0"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F0279209b-438d-42e6-bbe1-9e1e37ddfc1e?alt=media&token=ac31d4d4-04dc-4012-acd4-b0a600f47c8c"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F92cb805d-26b9-4f35-a5d4-db3ee75741c3?alt=media&token=e2e7c42a-5bcc-4d65-a520-7c08eb60f626"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F3e64ff76-3925-4792-85df-9a3471852403?alt=media&token=f8fe8102-0793-432e-812e-da64a6668791"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F6a9a5526-e029-4b29-a86e-d5768d5ddff8?alt=media&token=26e77df9-64a9-468b-aa8e-37d8c411a743"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F671b9060-3129-402e-aee8-24dc6a3bdb50?alt=media&token=9d1412d7-46f8-459e-88fc-d5cd6fc28d42"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F0eba8512-7d26-4167-9810-11525e58cf4c?alt=media&token=15fd57d8-0e70-47b2-9ee1-a268dc9a02c2"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F62927e0f-e7db-44c2-a328-177a719af24a?alt=media&token=dc3834d3-7da5-4721-bdc7-c6dc0d641e5d"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Ff2cf04e5-ec94-4dd4-b0b2-fe37af92830e?alt=media&token=cddb7066-0d35-4e21-a2d3-71023c579c3c"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Fcddb10bc-57e3-44ae-a8bf-ade0aa2163c5?alt=media&token=d6d9fcc5-6f28-4f7a-a617-60b3d0eeac23"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F3678f586-710c-45cf-a461-13a6f503dcdf?alt=media&token=e50ce52c-b0d0-4e45-b454-8814485918da"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Ffebf88f9-d47c-4331-95e0-2057322b6a2a?alt=media&token=269ff771-826e-404f-9c75-3ff2026265e1"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F186b6c7b-3333-4fa5-85ca-b22d433fd938?alt=media&token=dffefa5d-d9c8-4e3c-b43f-d00e00ab314c"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Faf0d00d3-c543-43a2-85c5-a54892641e07?alt=media&token=29bc7da4-658a-43b2-bbc0-2d1df46c3ab5"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Fb479e61e-4d20-419d-9211-5316e5681ec2?alt=media&token=a21107f6-576f-4484-a086-81b4384e2fd2"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Fddbb4556-c439-4951-9659-4c41da8b818f?alt=media&token=2a765848-3030-4534-b6ea-8d8a13d6e0e8"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F70ce6911-3687-4cf4-ae76-5d1af7e2f203?alt=media&token=ca8fe9a7-9c00-4685-ad91-753dfe6b5094"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F9fdfe43e-46e6-48ce-bf68-114cf86550ac?alt=media&token=ef8052c2-5216-4bc9-9f96-21881656f585"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2F377e4aeb-ee3b-4df7-9e6e-52b53d8f2c88?alt=media&token=534ea980-5f16-42d4-a2f2-50c62416b6e6"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2Ffaaf5356-8c03-40e0-a991-bd7153aa8a19?alt=media&token=52771f62-14f0-45df-b7cd-3f8116a5fb5b"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>




         
        </Slider>
        <div class="center"> 
        <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L2%2FB12U3L2NREF.pdf?alt=media&token=6c576c0d-8f15-484c-becf-f2d6dd7e8a38' download className="btn-download-dos">Download resources</Button>
       </div>
        </Grid.Column>
        <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

</Grid>
        
      //   </header>
      // </div>
      // </div>
    
    );
  }
}
