import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import '../../style.scss';
import './B11U1L3.scss';

export default class B11U1L3 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (

<Grid style={{ backgroundColor: '#101010'}}>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    
        <h2 className="lesson3title"><br></br>Lesson 3</h2>
        <Slider {...settings}>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F479bfa20-dcf9-41db-9706-fe3565387b8a?alt=media&token=69b425c0-175e-484c-839a-d531bba904ab"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F8ffda28a-7879-4282-b274-3cb0288204ec?alt=media&token=ca273029-a1b8-4382-af59-73223eeac5a5"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F6490227d-b649-4e99-aa4f-91c37f50010d?alt=media&token=b4333a17-67e9-4505-869c-c124c5f1e7e0"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F78e7dd14-4cf2-458d-ada7-d3e639457937?alt=media&token=8ca26cc2-fb6f-4c72-8360-6dc16370bacb"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2Fe3ea7088-e190-436c-9944-3e6d06e7863b?alt=media&token=b62c9540-bd02-4c01-a795-c8f0f8ae33fe"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F3d75efc2-e9f1-495a-a3dc-9f5ab9ed87af?alt=media&token=a6b09716-406e-4ec7-8bee-1bb1862632a4"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F14386c71-4ae5-43bd-afb4-61b28835085c?alt=media&token=be9cacb5-c63d-427b-81ac-790c9e68bbdb"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2Fbc42f898-ecee-44ba-ae40-b205ced8c91c?alt=media&token=2b58286f-0b57-4ddc-8908-7e44fdfa71d3"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F79d8164f-2d61-4e6e-b0f3-9502848355a8?alt=media&token=b7980bbd-6b86-43ef-bf5d-e9f04d296bcb"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F02d27dba-b437-4c2b-b57a-64e12d848b08?alt=media&token=d02a54bd-9612-4eb7-ada5-8141167b042e"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F9c0419a7-8210-45fe-b36a-9a4bd34a0a38?alt=media&token=81704eaf-1790-4d47-82f1-3d1b93dcf8a5"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F370fba99-7579-424d-ac2e-9cdd00b35359?alt=media&token=fb381bf3-2776-4260-ba0f-cc15a550b938"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F4a05c147-ac43-40b4-aaeb-fc7ade2ba7ea?alt=media&token=ce106905-aec0-4554-afdf-e7706e7a2b05"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2Fd9754fbb-75f3-4a5b-8bff-c0346f9aa4a5?alt=media&token=cc022242-3c46-40b6-bb6c-d6a166ae653e"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F08a1d0f0-20d8-4745-b1d4-8eadcf3d6126?alt=media&token=77e22fb4-553d-4eca-96e4-324f9bbf4441"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F6738fa3d-e522-478a-81d9-85ad6083d9f6?alt=media&token=68b61bfb-959c-40d4-9ff9-72785d3ca3ba"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F8ae65f3c-87b6-4c11-b3d8-b458f0c485ef?alt=media&token=2fd9eddc-4580-4404-8fbd-5e2dcb015ece"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F0faaa698-f235-497a-95fe-654ca0f68a17?alt=media&token=c70b5dec-d06e-4f57-99a1-c29ea9ff1283"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2Fc1816778-3342-4e94-912c-934c51e84a43?alt=media&token=2632033f-4aff-4562-ba4a-6700ddbd23af"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F48bfe819-96a8-430c-82ee-e44a84773006?alt=media&token=f88bed50-dc4a-422d-87f8-3f681d9b338c"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2Fb6eb5de1-f0ff-44d2-9344-605ae7e15d3b?alt=media&token=1a5cb0db-c15a-414f-8c1f-727fb39abad3"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2Fe955261a-f39c-4156-92b0-bbd20fe1504f?alt=media&token=e8c992e7-560b-43c4-a531-5054720e4ff5"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F2f422296-ad96-44b0-821f-6feb5ce98424?alt=media&token=e6a984f4-d626-47b3-bc9b-e56a2d5063f6"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F35c95e2d-7a24-4d19-9c3e-bc2cc0a0e134?alt=media&token=6a2da9ca-8944-4bb1-9cbe-c4d5bb2e61b0"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F666d49da-35fd-45b1-945e-53254a04a51f?alt=media&token=bd1cd61f-a92d-4a55-b413-4ec24980760b"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F7982cf9d-c920-4928-aa7e-6acc6f4fb3e2?alt=media&token=a1fc1246-69c5-4f74-a33e-7c812dc917ab"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F9f9d9d9c-0ddf-4995-9e8d-66e45b8a9088?alt=media&token=57fd0859-ce46-4036-a586-178b3a2efe7a"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F3a64ff80-af5b-4149-b10b-aa1bf901ccda?alt=media&token=5872aebc-b674-42f2-8a84-870f5250268e"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F9ed5b0a8-5ef6-476d-8e8b-bb6249ecf560?alt=media&token=5dcdec17-4d3c-4cf2-a193-1bb87c03a80d"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F785db1ec-6571-45bc-ae71-a01e070a9de0?alt=media&token=b2bd31aa-43a4-4694-b102-05c649168bff"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F995781b9-b848-4494-9912-db01a28b6510?alt=media&token=aa6a5d3f-f5df-4dcd-ba10-4a2a0a4bf286"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>
        <div>
          <img src={"https://firebasestorage.googleapis.com/v0/b/spanish-for-business.appspot.com/o/B1.2%2FB1.2%20Unit%203%2FB1.2%20Unit%203%20L3%2F52f9147c-d6c0-4cd5-b21f-c6520d50b017?alt=media&token=79f2b0b2-056c-431c-827d-72f884d97b36"} alt="boohoo" className="img-responsive"/>
          <br/>
        </div>






        </Slider>
        

</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

  
    );
  }
}


    